import type { FocusableElement, FocusableProps, Node, SelectionMode } from '@react-types/shared';
import type { ElementType, ForwardedRef, ReactNode } from 'react';
import type { VariantProps } from 'tailwind-variants';
import { useMemo, useRef } from 'react';

import { useHover } from '@react-aria/interactions';
import { useOption } from '@react-aria/listbox';
import { mergeProps } from '@react-aria/utils';
import { twMerge } from 'tailwind-merge';
import { tv } from 'tailwind-variants';

import type { OverrideProps } from '../../types';
import { createComponent, mergeRefs } from '../../utils';
import { Checkbox } from '../Checkbox';
import { useListBoxContext } from '../ListBoxBase/ListBoxContext';

export const listBoxItemVariants = tv({
  slots: {
    base: 'flex cursor-pointer items-center gap-2 rounded-md px-1 py-2',
    label: 'min-h-6 text-current',
    startContent: '',
  },
  variants: {
    isDisabled: {
      true: 'cursor-auto text-text-disabled',
    },
    isSelected: {
      true: 'bg-actions-selected font-semibold',
    },
    isHovered: {
      true: 'bg-actions-hover',
    },
    isFocused: {
      true: 'bg-actions-focus',
    },
    isPressed: {
      true: 'bg-actions-hover',
    },
    size: {
      md: {},
      sm: {
        base: 'px-0.5 py-1 text-sm',
        label: 'min-h-5',
      },
    },
  },
});

export interface ListBoxItemPrivateTypeMap<
  AdditionalProps = {},
  DefaultComponent extends React.ElementType = 'div',
> {
  props: AdditionalProps &
    FocusableProps &
    VariantProps<typeof listBoxItemVariants> & {
      /**
       * Whether the item is virtualized.
       */
      isVirtualized?: boolean;
      /**
       * Item object in the collection.
       */
      item: Node<object>;
      /**
       * The type of selection that is allowed in the collection.
       */
      selectionMode?: SelectionMode;
      /**
       * The content to display at the start of the item.
       */
      startContent?: ReactNode;
    };
  defaultComponent: DefaultComponent;
}

export type ListBoxItemPrivateProps<
  RootComponent extends ElementType = ListBoxItemPrivateTypeMap['defaultComponent'],
  AdditionalProps = {},
> = OverrideProps<ListBoxItemPrivateTypeMap<AdditionalProps, RootComponent>, RootComponent>;

/**
 * @private
 */
export const ListBoxItemPrivate = createComponent<ListBoxItemPrivateTypeMap>(
  <BaseComponentType extends React.ElementType = ListBoxItemPrivateTypeMap['defaultComponent']>(
    inProps: ListBoxItemPrivateProps<BaseComponentType>,
    ref: ForwardedRef<Element>,
  ) => {
    const {
      as: Component = 'div',
      startContent: startContentProp,
      isVirtualized,
      item,
      className,
      selectionMode,
      size = 'md',
    } = inProps;
    const itemRef = useRef<FocusableElement>(null);

    const { rendered, key, props } = item;

    const { state } = useListBoxContext()!;

    const { optionProps, labelProps, isFocused, isDisabled, isPressed, isSelected } = useOption(
      {
        'aria-label': item['aria-label'],
        isVirtualized,
        key,
      },
      state,
      itemRef,
    );
    const { hoverProps, isHovered } = useHover({ isDisabled });

    const startContent = useMemo(() => {
      if (selectionMode === 'multiple') {
        return (
          <Checkbox
            aria-labelledby={labelProps.id}
            isReadOnly
            isSelected={isSelected}
          />
        );
      }

      return startContentProp ?? (props.startContent as ReactNode);
    }, [selectionMode, startContentProp, props.startContent, labelProps.id, isSelected]);

    const styles = useMemo(
      () =>
        listBoxItemVariants({
          className: twMerge(className, props.className),
          isDisabled,
          isFocused,
          isHovered,
          isPressed,
          isSelected,
          size,
        }),
      [className, isDisabled, isFocused, isHovered, isPressed, isSelected, props.className, size],
    );

    return (
      <Component
        {...mergeProps(optionProps, hoverProps)}
        className={styles.base({
          className: twMerge(className, props.className),
        })}
        ref={mergeRefs(itemRef, ref)}
      >
        {startContent && <div className={styles.startContent()}>{startContent}</div>}
        <div
          {...labelProps}
          className={styles.label()}
        >
          {rendered}
        </div>
      </Component>
    );
  },
);
